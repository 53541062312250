import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import './Overview.css'; // Create this CSS file for styling

const Overview = () => {
  return (
    <Box className="overview-section  bg-image-1">
      <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 2 , marginTop:'1%',marginBottom:'5%', Height:'300px'}}>
          <Typography variant="h3" gutterBottom sx={{ color: '#000',fontFamily:'Cambria'}}>
          Our Story<br/>&nbsp;
        </Typography>  
        <Typography variant="body1" paragraph  sx={{   Height:'400px',fontFamily:'Cambria',color:'#000'}}>
{/*        <h4 style={{color:'#000',marginLeft:'3%'}}><strong>A story of flavour and excellence: The journey of Foodcoast International</strong> </h4>
 */}        <p className="overviewP">
       
In the heart of culinary innovation stands Foodcoast International, a proud unit of the illustrious HR Group of Industries, recognised globally with ISO 9001: 2000 & ISO 14000 certifications. Born out of a passion for delivering authentic Indian flavours to the world, Foodcoast has grown into a beacon of excellence in the processed food industry. Imagine the hum of state-of-the-art machinery, the meticulous care of a finely tuned quality control system, and the seamless support and planning of a dedicated workforce – all working in harmony to craft sauces, dips, mayonnaise, gravies, toppings & crushes that don’t just satisfy taste buds but also tell a story of perfection. These pillars of commitment form the bedrock of Foodcoast’s success, earning it a reputation for unmatched quality and flavour. 
</p>  <p className="overviewP">
With a vast network of 13 depots and over 500 prominent distributors spread across India, Foodcoast has woven its way into the heart of the nation under its celebrated brand names FOODCOAST, FC Plus and Nieu. It’s presence in the HoReCa segment is a testament to its widespread acclaim, with products proudly gracing the kitchens of leading national, regional and local foodservice chains. We are currently committed with esteemed brands like Dominos, Hongs Kitchen, Chefboss, IKEA, Parsuma, Cornitos, Luv It and many reputed regional players active in B2B industry.
</p><p className="overviewP">But Foodcoast’s story doesn’t end here. It’s a table of global ambition, with its delectable range travelling across borders to countries like UK, Singapore, Hong Kong, Australia, Liberia, Sierra Leone, UAE, Iraq & Nepal. As a private-label exporter and a trusted co-manufacturer for Nestle and Dabur, Foodcoast is not just shaping the Indian food landscape but is also leaving its mark on the global palate. At Foodcoast International, every product is more than just quality food- it’s a celebration of flavour, a promise of excellence, and a journey of innovation and trust.
   </p>
        </Typography>
      </Container>
    </Box>
  );
};

export default Overview;

  