import React from 'react';
import Slider from 'react-slick';
import { Container, Image } from 'react-bootstrap';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './CategoryCarousel.css';

/* const images = [
  { src: 'images/icons/MayoIcons.png', title: 'Mayonnaise', link: '/mayonnaise' },
  { src: 'images/icons/ketchups.png', title: 'Tomato Ketchup', link: '/tomato-ketchup' },
  { src: 'images/icons/SandwichSpread.png', title: 'Sandwich Spreads', link: '/sandwich-spreads' },
  { src: 'images/icons/dressing.png', title: 'Salad Dressing', link: '/salad-dressings' },
  { src: 'images/icons/Sauces.png', title: 'Chinese Sauces', link: '/chinese-sauces' },
  { src: 'images/icons/ItalianSauces.png', title: 'Italian Sauces', link: '/italian-sauces' },
  { src: 'images/icons/cookingSauces.png', title: 'Cooking Sauces', link: '/cooking-sauces' },
  { src: 'images/icons/dips.png', title: 'Dips', link: '/dips' },
  { src: 'images/icons/peanuntsButter.png', title: 'Peanut Butter', link: '/peanuts-butter' },
  { src: 'images/icons/sweetTopping.png', title: 'Sweet Toppings', link: '/sweet-topping' },
  { src: 'images/icons/Chutneys.png', title: 'Chutneys', link: '/chutneys' }/* ,
  { src: 'images/icons/salsa.png', title: 'Salsa', link: '/salsa' }  
  
]; 
*/
const images = [
  { src: 'images/icons/1.png', title: 'Mayonnaise', link: '/mayonnaise' },
  { src: 'images/icons/2.png', title: 'Tomato Ketchup', link: '/tomato-ketchup' },
  { src: 'images/icons/3.png', title: 'Sandwich Spreads', link: '/sandwich-spreads' },
  { src: 'images/icons/4.png', title: 'Salad Dressing', link: '/salad-dressings' },
  { src: 'images/icons/5.png', title: 'Chinese Sauces', link: '/chinese-sauces' },
  { src: 'images/icons/6.png', title: 'Italian Sauces', link: '/italian-sauces' },
  { src: 'images/icons/7.png', title: 'Cooking Sauces', link: '/cooking-sauces' },
  { src: 'images/icons/8.png', title: 'Dips', link: '/dips' },
  { src: 'images/icons/9.png', title: 'Peanut Butter', link: '/peanuts-butter' },
  { src: 'images/icons/10.png', title: 'Sweet Toppings', link: '/sweet-topping' },
  { src: 'images/icons/11.png', title: 'Chutneys', link: '/chutneys' }/* ,
  { src: 'images/icons/salsa.png', title: 'Salsa', link: '/salsa' } */
  
];
const CategoryCarousel = () => {
  const settings = {
    dots: false, // Remove pagination dots
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <Container fluid className=" bg-image-1 category-padding">
      <Slider {...settings}>
        {images.map((image, idx) => (
          <div key={idx} className="carousel-image-col">
            <a href={image.link} className="image-container">
              <Image src={image.src} alt={image.title} className="d-block w-40 category-image" />
              <div className="image-title">{image.title}</div>
            </a>
          </div>
        ))}
      </Slider>
    </Container>
  );
};

export default CategoryCarousel;
