import React from 'react';
import Slider from 'react-slick';
import { Container, Image } from 'react-bootstrap';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './ClientNetworkSection.css';

const images = [
  { src: 'images/Countrylogo/UK.png', title: 'UK' },
  { src: 'images/Countrylogo/Singapore.png', title: 'Singapore' },
  { src: 'images/Countrylogo/Hong_Kong.png', title: 'Hong Kong' },
  { src: 'images/Countrylogo/Australia.png', title: 'Australia' },
  { src: 'images/Countrylogo/UAE.png', title: 'UAE' },
  { src: 'images/Countrylogo/Iraq.png', title: 'Iraq' },
  { src: 'images/Countrylogo/Nepal.png', title: 'Nepal' },
];
const NextArrow = ({ onClick }) => (
  <div className="slick-next" onClick={onClick}>
    &#8250; {/* HTML code for right arrow */}
  </div>
);

const PrevArrow = ({ onClick }) => (
  <div className="slick-prev" onClick={onClick}>
    &#8249; {/* HTML code for left arrow */}
  </div>
);

const ClientNetworkSection = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <Container fluid className="bg-image-1 category-padding">
     <h2 className="wow fadeScale">Our Global Presence</h2>
      <Slider {...settings}>
        {images.map((image, idx) => (
          <div key={idx} className="carousel-cn-image-col">
            <Image src={image.src} alt={image.title}   className="d-block w-50 category-cn-image" />
           
          </div>
        ))}
      </Slider>
    </Container>
  );
};

export default ClientNetworkSection;
