import React from 'react';
import { Container } from 'react-bootstrap';
import '@mdi/font/css/materialdesignicons.min.css';  // Import Material Design Icons CSS
import './Footer.css';
const images = [
  { src: "../images/social/insta.png",link: 'https://www.instagram.com/foodcoast_int' },
   { src: "../images/social/fb.png",link:  'https://www.facebook.com/foodcoastINT' },
   { src: "../images/social/linkedin.png",link: 'https://www.linkedin.com/company/foodcoast-international/'  },  
    { src: "../images/social/x.png",link: 'https://x.com/foodcoast_com'  },  
  { src: "../images/social/yt.png",link: 'https://youtube.com/@foodcoastinternational' }];
const Footer = () => {
  return (
    <footer className=" footer-modern">
      <div className="footer-corporate-body section-lg">
        <Container>
          <div className="row row-40 row-md-50 justify-content-xl-between">
            {/* Contact Section */}
            <div className="col-sm-6 col-lg-4 wow fadeInRight">
              <h5 className="footer-corporate-title">Contact</h5>
              <div className="footer-corporate-decor"></div>
              <ul className="footer-corporate-info">
                <li>
                  <div className="unit flex-column flex-sm-row align-items-center">
                    <div className="unit-left">
                      <span className="icon mdi mdi-map-marker"></span>
                    </div>
                    <div className="unit-body">
                      <a href="#">
                        Foodcoast International
                        <br />
                        A-23A Focal Point Extension
                        <br />
                        Jalandhar - 144004 Punjab. INDIA
                      </a>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="unit flex-column flex-sm-row align-items-center">
                    <div className="unit-left">
                      <span className="icon mdi mdi-phone"></span>
                    </div>
                    <div className="unit-body">
                      <ul className="list-schedule">
                        <li><span>1800 274 274 0 (Toll Free)</span></li>
                        <li><span>+91 181 5077771/72</span></li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="unit flex-column flex-sm-row align-items-center">
                    <div className="unit-left">
                      <span className="icon mdi mdi-email"></span>
                    </div>
                    <div className="unit-body">
                      <ul className="list-schedule">
                        <li><span>support@foodcoast.com</span></li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

            {/* Quick Links Section */}
            <div className="col-sm-6 col-lg-4 wow fadeInRight" data-wow-delay=".1s">
              <h5 className="footer-corporate-title">Quick links</h5>
              <div className="footer-corporate-decor"></div>
              <ul className="footer-corporate-list d-sm-inline-block d-md-block">
                <li><a href="./">Home</a></li>
                <li><a href="/about">About</a></li>
               
                <li><a href="/foodcoast">Brands</a></li>
                <li><a href="/gallery">Gallery</a></li>
                <li><a href="/sustainability">Sustainability</a></li>
                <li><a href="/contact">Contact</a></li>
              </ul>
            </div>

            {/* Get in Touch Section */}
            <div className="col-lg-4 wow fadeInRight" data-wow-delay=".2s">
            <h5 className="footer-corporate-title">Follow Us</h5>
              <div className="footer-corporate-decor"></div>
              <div className="row">
             {images.map((image, index) => (
              <div key={index} className="col-md-1">
                <a href={image.link} target="_blank" rel="noopener noreferrer">
                <img
                  src={image.src}
                  alt={image.alt}
                  className="zoomImg"
                  style={{
                    
                    height: '28px',
                    borderRadius: '8px',
                    cursor: 'pointer',
                  }}
                  
                /></a>
                </div> ))}</div>
            </div>
          </div>
        </Container>
      </div>

      {/* Footer Panel */}
      <div className="footer-corporate-panel">
        <Container>
          <div className="row row-10 row-sm-30 align-items-center justify-content-sm-between">
            <div className="col-sm-auto col-lg-4 text-lg-start"></div>
            <div className="col-sm-auto col-lg-4">
              
            </div>
            <div className="col-lg-4 text-lg-end">
  <p className="rights">
    <span>&copy;&nbsp;</span>
    <span className="copyright-year">{new Date().getFullYear()}</span>
    <span>&nbsp;</span>
    <span>Foodcoast International</span>
    <span>.&nbsp;All rights reserved.</span>
    <span>&nbsp;</span>
    <a href="#">Privacy Policy</a>
    <span>.</span>
  </p>
</div>
          </div>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
