import React from 'react';
import CustomNavbar from '../Components/CustomNavbar';
import ContactForm from '../Components/ContactForm';
import Footer from '../Components/Footer';
const ContactUs = () => {
  return (
    <>
      <CustomNavbar />
     
        
        <ContactForm />
    
      
   
    <Footer/>  
    </>
  );
};

export default ContactUs;
