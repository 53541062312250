import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './Sustainability.css'; // Create and include a CSS file for custom styles
import image1 from '../images/sustainability/1.png'; // Import the image
import image2 from '../images/sustainability/2.png'; // Import the image

const Sustainability = () => {
  return (
    <>
      {/* Breadcrumbs Section */}
     {/*  <section className="breadcrumbs-custom" style={{ marginBottom: '5em' }}>
        <div
          className="parallax-container"
          style={{ backgroundImage: "url('images/banner/sustainability.jpeg')" }}
        >
          <div className="breadcrumbs-custom-body parallax-content context-dark right-banner-text">
            <Container  style={{ color: '#333' }}>
              <h2 className="breadcrumbs-custom-title">Sustainability</h2>
              <h5 className="breadcrumbs-custom-text">
                Foodcoast International - Authentic Freshness & Flavour
              </h5>
            </Container>
          </div>
        </div>
        <div className="breadcrumbs-custom-footer">
          <Container>
            <ul className="breadcrumbs-custom-path">
              <li>
                <a href="../">Home</a>
              </li>
              <li className="active">Sustainability</li>
            </ul>
          </Container>
        </div>
      </section> */}

      {/* About Us Section 1 */}
      <section className="section bg-image-1 text-md-start" style={{ padding: "50px 0" }}>
        <Container>
        <h2 className="text-center mb-4">Sustainability</h2>
          <Row className="row-40 row-md-60 justify-content-center align-items-xl-center">
            <Col md={11} lg={11} xl={11}>
              <article className="quote-classic-big inset-xl-right-30">
                <p style= {{fontSize:'18px'}}>
                At Foodcoast, we believe the time to act is now. The alarming effects of climate change, depletion of natural resources, and the degradation of our environment are clear signs of the damage caused by human activity over centuries. It’s our responsibility to reverse these trends and preserve our planet for future generations. 
                </p>
                <p style= {{fontSize:'18px'}}>
                Our commitment to sustainability is deeply rooted in our mission to innovate and uphold the highest standards in the food industry. We strive to minimize our environmental footprint while delivering quality to our consumers. 
                </p>
                {/* <p>
                  We are collecting and repurposing more plastic each year than we add to our environment,
                  thus ensuring that we help maintain the delicate balance in nature.
                </p>
                <p>
                  All the plastic that we use is recyclable, and we have partnered with ReKart to ensure
                  transparent and responsible processing of all the plastic collected.
                </p> */}
              </article>
            </Col>
           {/*  <Col md={11} lg={4} xl={4}>
              <div className="slick-slider-1 inset-xl-left-35">
                <img
                  src="../images/GIf_02.webp"
                  alt="Sustainability Visual"
                  width="634"
                  height="373"
                />
              </div>
            </Col> */}
          </Row>
        </Container>
      </section>

      {/* About Us Section 2 */}
     {/*  <section className="section text-md-start" style={{ background: "#ddeef6" }}>
        <Container>
          <Row className="row-40 row-md-60 justify-content-center align-items-xl-center">
            <Col md={11} lg={4} xl={4}>
              <div className="slick-slider-1 inset-xl-left-35">
                <img
                  src="images/our-initiatives.png"
                  alt="Sustainability Visual"
                  width="634"
                  height="373"
                />
              </div>
            </Col>
            <Col md={11} lg={8} xl={8}>
              <article className="quote-classic-big inset-xl-left-35">
              <h3>Our Initiatives</h3> 
              <h4 style={{textAlign:'left'}}>Plastic Positive</h4> 

              <p  style={{textAlign:'left'}}>We ensure that more plastic is removed and repurposed from the environment than we use. This helps maintain the ecological balance and reduces waste. 
</p><h4 style={{textAlign:'left'}}>Recyclable Materials</h4>
<p  style={{textAlign:'left'}}>
All the plastic we use in our products is 100% recyclable. Through strategic partnerships with leading waste management organizations, we ensure responsible recycling and repurposing of collected plastic. 
</p><h4 style={{textAlign:'left'}}>Sustainable Processing</h4>
<p  style={{textAlign:'left'}}>Our focus is on collecting plastic waste in all forms and converting it into reusable materials with minimal environmental impact, contributing to a cleaner and greener planet. 
</p>
                <h4>
                {/ * <p  style={{textAlign:'left'}}>  Our efforts are currently aimed at collecting plastic in all forms, ensuring that they
                  are processed and repurposed into a usable form with minimal impact on the environment.</p> * / }
                </h4>
              </article>
            </Col>
          </Row>
        </Container>
      </section> */}

      {/* How We Do It Section */}
    {/*   <section className="section bg-image-1 text-md-start" style={{ padding: "50px 0" }}>
        <Container>
    */}        
         {/*  <Row className="row-40 row-md-60 justify-content-center align-items-xl-center">
            {[
              {
                img: "images/001-1668768361792.webp",
                title: "Minimize",
                description:
                  "We carefully design our packaging to use the least amount of plastic required to safely deliver our products nationwide. ",
              },
              {
                img: "images/003-1668768377512.webp",
                title: "Remove",
                description:
                  "By addressing plastic waste directly, we help prevent it from accumulating in landfills and harming the environment. ",
              },
              {
                img: "images/002-1668768395607.gif",
                title: "Reduce",
                description:
                  "We recycle and repurpose plastic waste, ensuring that we reduce our overall plastic footprint. ",
              },
              {
                img: "images/004-1668768411590.webp",
                title: "Collaborate",
                description:
                  "We work with trusted partners to implement sustainable practices and drive positive change in waste management. ",
              },
            ].map((item, index) => (
              <Col md={11} lg={3} xl={3} key={index}>
                <div style={{ textAlign: "center" }}>
                  <img src={item.img} alt={item.title} />
                  <h4>{item.title}</h4>
                  <p style= {{fontSize:'18px'}}>{item.description}</p>
                </div>
              </Col>
            ))}
          </Row> */}

<Container fluid className="why-choose-us" style={{ padding: '40px 1%' }}>
      <h2 className="text-center mb-4">Our Initiatives</h2>
      <Row>
     

        <Col md={12} className="text-center">
         
            <Card className="mb-3 border-0">
              <Card.Body>
                <img src={image1} alt="Why Us" style={{ width: '100%', borderRadius: '10px' }} />
              </Card.Body>
            </Card>
         
        </Col>

        
      </Row>
    </Container>

    <Container fluid className="why-choose-us" style={{ padding: '40px 1%' }}>
      <h2 className="text-center mb-4">Our Approach</h2>
      <Row>
     

        <Col md={12} className="text-center">
         
            <Card className="mb-3 border-0">
              <Card.Body>
                <img src={image2} alt="Why Us" style={{ width: '100%', borderRadius: '10px' }} />
              </Card.Body>
            </Card>
         
        </Col>

        
      </Row>
    </Container>
      
      <section
  className="section text-md-start"
  style={{
    backgroundColor: `rgb(248 249 250);`,padding:'1em!important'
    
  }}
>
      <Container style={{backgroundColor:'rgb(248 249 250);'}}>
          <Row className="row-40 row-md-60 justify-content-center align-items-xl-center">
            <Col md={12} lg={10} xl={10}><p  style={{textAlign:'left',fontSize:'18px'}}>  At Foodcoast, sustainability isn’t just a responsibility—it’s a promise to protect our planet and support a healthier tomorrow.
           </p>
           </Col>
           </Row>
           </Container></section>
    </>
  );
};

export default Sustainability;
