import React from 'react';
import CustomNavbar from '../Components/CustomNavbar';
/* import Join from '../Components/JoinUs'; */
import YummyliciousRecipes from '../Components/YummyliciousRecipes';
import Footer from '../Components/Footer';
const Recipes = () => {
  return (
    <>
      <CustomNavbar />
     
        
        <YummyliciousRecipes />
    
      
   
    <Footer/>  
    </>
  );
};

export default  Recipes;
