/*import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "./SliderTop.css"; // Include custom styles

const ResponsiveSlider = () => {
  const [randomAnimation, setRandomAnimation] = useState("slide");

  // Update the animation type randomly before the next transition
  useEffect(() => {
    const interval = setInterval(() => {
      const animations = ["slide", "fade"];
      setRandomAnimation(animations[Math.floor(Math.random() * animations.length)]);
    }, 3000); // Match autoplaySpeed for consistency

    return () => clearInterval(interval);
  }, []);

  const sliderSettings = {
    dots: true, // Show pagination dots
    infinite: true, // Enable infinite scrolling
    speed: 800, // Transition speed in milliseconds
    slidesToShow: 1, // Show only 1 slide at a time
    slidesToScroll: 1, // Scroll one slide at a time
    autoplay: true, // Enable autoplay
    autoplaySpeed: 3000, // Delay between slides (ms)
    fade: randomAnimation === "fade", // Apply fade animation if randomAnimation is "fade"
    arrows: true, // Enable navigation arrows
    nextArrow: <CustomArrow direction="next" />,
    prevArrow: <CustomArrow direction="prev" />,
  };

  const sliderItems = [
    { id: 1, image: "images/slider/1.jpg", title: " " },
    { id: 2, image: "images/slider/2.jpg", title: " " },
    { id: 3, image: "images/slider/3.jpg", title: " " },
    { id: 4, image: "images/slider/4.jpg", title: " " },
    { id: 5, image: "images/slider/5.jpg", title: " " }
  ];

  return (
    <div className="responsive-slider-container">
      <Slider {...sliderSettings}>
        {sliderItems.map((item) => (
          <div key={item.id} className="slider-item">
            <img src={item.image} alt={item.title} className="slider-image" />
            <h3 className="slider-title">{item.title}</h3>
          </div>
        ))}
      </Slider>
    </div>
  );
};

const CustomArrow = ({ direction, onClick }) => {
  return (
    <div
      className={`custom-arrow ${direction}`}
      onClick={onClick}
    >
      {direction === "next" ? ">" : "<"}
    </div>
  );
};

export default ResponsiveSlider;
*/
import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "./SliderTop.css"; // Include custom styles
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const ResponsiveSlider = () => {
  const [randomAnimation, setRandomAnimation] = useState("slide");

  // Update the animation type randomly before the next transition
  useEffect(() => {
    const interval = setInterval(() => {
      const animations = ["slide", "fade"];
      setRandomAnimation(
        animations[Math.floor(Math.random() * animations.length)]
      );
    }, 3000); // Match autoplaySpeed for consistency

    return () => clearInterval(interval);
  }, []);

  const sliderSettings = {
    dots: true, // Show pagination dots
    infinite: true, // Enable infinite scrolling
    speed: 800, // Transition speed in milliseconds
    slidesToShow: 1, // Show only 1 slide at a time
    slidesToScroll: 1, // Scroll one slide at a time
    autoplay: true, // Enable autoplay
    autoplaySpeed: 3000, // Delay between slides (ms)
    fade: randomAnimation === "fade", // Apply fade animation if randomAnimation is "fade"
    arrows: true, // Enable navigation arrows
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  };

  const sliderItems = [
    { id: 1, image: "images/slider/5.jpg" },
    { id: 2, image: "images/slider/1.jpg" },
    { id: 3, image: "images/slider/2.jpg" },
    { id: 4, image: "images/slider/3.jpg" },
    { id: 5, image: "images/slider/4.jpg" },
  ];

  return (
    <div className="responsive-slider-container">
      <Slider {...sliderSettings}>
        {sliderItems.map((item) => (
          <div key={item.id} className="slider-item">
            <img
              src={item.image}
              alt={`Slide ${item.id}`}
              className="slider-image"
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};
const CustomNextArrow = ({ onClick }) => {
  return (
    <div
      className="custom-arrow next-arrow"
      onClick={onClick}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        zIndex: 1,
        position: "absolute",
        top: "50%",
        right: "15px",
        transform: "translateY(-50%)",
        background: "rgba(0, 0, 0, 0.5)",
        borderRadius: "50%",
        padding: "20px", // Increased padding for a larger clickable area
        width: "50px", // Optional: Adjust width
        height: "50px", // Increased height
      }}
    >
      <ArrowForwardIosIcon style={{ color: "#fff", fontSize: "24px" }} /> {/* Adjust fontSize if needed */}
    </div>
  );
};

const CustomPrevArrow = ({ onClick }) => {
  return (
    <div
      className="custom-arrow prev-arrow"
      onClick={onClick}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        zIndex: 1,
        position: "absolute",
        top: "50%",
        left: "15px",
        transform: "translateY(-50%)",
        background: "rgba(0, 0, 0, 0.5)",
        borderRadius: "50%",
        padding: "20px", // Increased padding for a larger clickable area
        width: "50px", // Optional: Adjust width
        height: "50px", // Increased height
      }}
    >
      <ArrowBackIosIcon style={{ color: "#fff", fontSize: "24px" }} /> {/* Adjust fontSize if needed */}
    </div>
  );
};

export default ResponsiveSlider;