import React from 'react';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom'; // Use NavLink for active link management
import './Navbar.css'; // Add CSS file
import logo from '../images/foodcoast-logo.png';

const CustomNavbar = () => {
  return (
    <div className="custom-navbar-container">
      <Navbar expand="lg" className="custom-navbar" style={{padding: '1px 200px !important'}}>
        <Container fluid>
          <Navbar.Brand href="/">
            <img src={logo} alt="Logo" className="navbar-logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="me-auto my-2 my-lg-0 navbar-menu" navbarScroll>
              <Nav.Link
                as={NavLink}
                to="/"
                exact
                className="navbar-item"
                activeClassName="active"
              >
                Home
              </Nav.Link>
              <Nav.Link
                as={NavLink}
                to="/about"
                className="navbar-item"
                activeClassName="active"
              >
                About
              </Nav.Link>
              <NavDropdown
                title={
                  <span className="navbar-item">
                    Brands<i className="bi bi-chevron-down dropdown-icon"></i>
                  </span>
                }
                id="navbarScrollingDropdown"
              >
                <NavDropdown.Item href="/foodcoast" className="navbar-items">
                  Foodcoast 
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="/nieu" className="navbar-items">
                  Nieu 
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link
                as={NavLink}
                to="/sustainability"
                className="navbar-item"
                activeClassName="active"
              >
                Sustainability
              </Nav.Link>
              <Nav.Link
                as={NavLink}
                to="/gallery"
                className="navbar-item"
                activeClassName="active"
              >
                Gallery
              </Nav.Link>
              <Nav.Link
                as={NavLink}
                to="/recipes"
                className="navbar-item"
                activeClassName="active"
              >
               Recipes
              </Nav.Link>
              <Nav.Link
                as={NavLink}
                to="/contact"
                className="navbar-item"
                activeClassName="active"
              >
                Contact
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default CustomNavbar;
