import React from 'react';
import CustomNavbar from '../Components/CustomNavbar';
import Salsa from '../Components/Salsa';
import Footer from '../Components/Footer';


const Salsaa = () => {
  return (
    <>
    <CustomNavbar/>
    <Salsa/>
    <Footer/>  
    
    </>
  );
};

export default Salsaa;