import React from 'react';
import Slider from 'react-slick';
import { Container, Image } from 'react-bootstrap';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './ClientNetworkSection.css';

const images = [
  'images/brands/burger-king-logo.png',
  'images/brands/ChefBoss-Logo.png',
  'images/brands/cropped-logo-6.png',
  'images/brands/dominos-logo.png',
  'images/brands/haldirams.png',
  'images/brands/dabur.png',
  'images/brands/country-delight.png',
  'images/brands/hong_logo2.png',
  'images/brands/ikea.png', 
  'images/brands/nestle.jpeg',
  'images/brands/luvit.png',
  'images/brands/PRASUMA_Logo.jpg',
  'images/brands/bercos.jpg',
  'images/brands/rebel-foods.png'
];

const NextArrow = ({ onClick }) => (
  <div className="slick-next" onClick={onClick}>
    &#8250; {/* HTML code for right arrow */}
  </div>
);

const PrevArrow = ({ onClick }) => (
  <div className="slick-prev" onClick={onClick}>
    &#8249; {/* HTML code for left arrow */}
  </div>
);

const ClientNetworkSection = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <Container fluid className="category-padding">
      <h2 className="wow fadeScale">Our Happy Clients</h2>
      <Slider {...settings}>
        {images.map((image, idx) => (
          <div key={idx} className="carousel-cn-image-col">
            <Image src={image} alt={`Category ${idx + 1}`} className="d-block w-50 category-cn-image" />
           
          </div>
        ))}
      </Slider>
    </Container>
  );
};

export default ClientNetworkSection;
