import React from 'react';
import CustomNavbar from '../Components/CustomNavbar';
import About from '../Components/AboutUs';
import Footer from '../Components/Footer';


const AboutUs = () => {
  return (
    <>
    <CustomNavbar/>
  
    <About/> 
   
    <Footer/>  
    
    </>
  );
};

export default AboutUs;