import React from 'react';
import './ChineseSauces.css'; // Custom styles
import { Container } from 'react-bootstrap';

const ChineseSauces = () => {
  const images = [
    { src: "../images/fc-range/chinese/chinese-sauce.png", alt: "Chinese Sauce", title: "Chinese Sauce" },
  ];

  return (
    <>
      {/* Image Gallery Section */}
      <section className="section bg-image-1 text-md-start" style={{ padding: "50px 0" }}>
        <Container>
          <h2 className="text-center mb-4">Chinese Sauces</h2>
          <div className="row justify-content-center">
            {images.map((image, index) => (
              <div
                key={index}
                className="col-md-4 col-sm-6 col-6 image-container mb-4"
                style={{
                  width: '300px', // Decreased width
                  margin: '20px', // Increased margin
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img
                  src={image.src}
                  alt={image.alt}
                  style={{
                    maxWidth: '90%',
                    objectFit: 'contain',
                  }}
                />
                <p style={{ marginTop: '5px', fontSize: '18px', color: '#555' }}>
                  {image.title}
                </p>
              </div>
            ))}
          </div>
        </Container>
      </section>
    </>
  );
};

export default ChineseSauces;
