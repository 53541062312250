import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import './ContactForm.css';

const images = [
  { src: "../images/social/insta.png", link: 'https://www.instagram.com/foodcoast_int' },
  { src: "../images/social/fb.png", link: 'https://www.facebook.com/foodcoastINT' },
  { src: "../images/social/linkedin.png", link: 'https://www.linkedin.com/company/foodcoast-international/' },
  { src: "../images/social/x.png", link: 'https://x.com/foodcoast_com' },
  { src: "../images/social/yt.png", link: 'https://youtube.com/@foodcoastinternational' },
];

const ContactForm = () => {
  const [states, setStates] = useState([]); // List of states
  const [cities, setCities] = useState([]); // List of cities for selected state
  const [selectedState, setSelectedState] = useState(''); // Currently selected state
  const [selectedCity, setSelectedCity] = useState(''); // Currently selected city
  const [fetchError, setFetchError] = useState(null); // Error if JSON load fails

  useEffect(() => {
    // Fetch the cities.json file
    fetch('/cities.json')
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        const stateList = Object.keys(data).sort(); // Sort the states alphabetically
        setStates(stateList); // Populate the states
        setFetchError(null); // Clear any previous errors
      })
      .catch((error) => {
        console.error('Error loading cities.json:', error);
        setFetchError('Failed to load data');
      });
  }, []);

  const handleStateChange = (event) => {
    const stateName = event.target.value;
    setSelectedState(stateName);
    setSelectedCity(''); // Reset city selection when state changes

    // Fetch cities for the selected state
    fetch('/cities.json')
      .then((response) => response.json())
      .then((data) => {
        const cityList = (data[stateName] || []).sort(); // Sort the cities alphabetically
        setCities(cityList); // Set cities for the selected state
      })
      .catch((error) => {
        console.error('Error fetching cities:', error);
      });
  };

  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = {
      name: event.target.formName.value,
      email: event.target.formEmail.value,
      state: selectedState,
      city: selectedCity,
      message: event.target.formMessage.value,
    };

    fetch('/send-mail.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (response.ok) {
          alert('Your message has been sent successfully!');
          event.target.reset();
          setSelectedState('');
          setSelectedCity('');
        } else {
          alert('Failed to send the message. Please try again.');
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        alert('An error occurred. Please try again.');
      });
  };

  return (
    <Container className="contact-container" style={{ marginTop: '2em' }}>
      <Row>
        <Col md={6}>
          <h2>Contact Us</h2>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" placeholder="Enter your name" name='name' required />
            </Form.Group>

            <Form.Group controlId="formEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" placeholder="Enter your email" name='email' required />
            </Form.Group> 
            <Form.Group controlId="formEmail">
              <Form.Label>Phone</Form.Label>
              <Form.Control type="tel" placeholder="Enter your Phone" name='phone'  required />
            </Form.Group>

            <Form.Group controlId="formState">
              <Form.Label>State</Form.Label>
              <Form.Control as="select" onChange={handleStateChange} name='state'  value={selectedState} required>
                <option value="">Select State</option>
                {states.map((state, index) => (
                  <option key={index} value={state}>
                    {state}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="formCity">
              <Form.Label>City</Form.Label>
              <Form.Control as="select" onChange={handleCityChange} name='city' value={selectedCity} required>
                <option value="">Select City</option>
                {cities.map((city, index) => (
                  <option key={index} value={city}>
                    {city}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="formMessage">
              <Form.Label>Message</Form.Label>
              <Form.Control as="textarea" rows={3} placeholder="Enter your message" name='message' required />
            </Form.Group>
            <br />
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </Col>
        <Col md={1}></Col>
        <Col md={5}>
          <h2>Get in Touch</h2>
          Foodcoast International
          <br />
          A-23A Focal Point Extension
          <br />
          Jalandhar - 144004 Punjab. INDIA
          <p>
            <strong>Email:</strong>{' '}
            <a href="mailto:info@foodcoast.com" className="atext">
              info@foodcoast.com
            </a>
          </p>
          <p>
            <strong>Phone:</strong>{' '}
            <a href="tel:+18002742740" className="atext">
              1800 274 274 0 (Toll Free)
              <br />
              +91 181 5077771/72
            </a>
          </p>

          <h5 className="footer-corporate-title">Follow Us</h5>
          <div className="footer-corporate-decor"></div>
          <div className="row">
            {images.map((image, index) => (
              <div key={index} className="col-md-1">
                <a href={image.link} target="_blank" rel="noopener noreferrer">
                  <img
                    src={image.src}
                    alt={`Social Media ${index}`}
                    className="zoomImg"
                    style={{
                      height: '28px',
                      borderRadius: '8px',
                      cursor: 'pointer',
                    }}
                  />
                </a>
              </div>
            ))}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ContactForm;