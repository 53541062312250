import React from 'react';
import CustomNavbar from '../Components/CustomNavbar';
import SweetTopping from '../Components/SweetToppings';
import Footer from '../Components/Footer';


const SweetToppings = () => {
  return (
    <>
    <CustomNavbar/>
    <SweetTopping/>
    <Footer/>  
    
    </>
  );
};

export default SweetToppings;