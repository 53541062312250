/* import React from 'react';
import CustomNavbar from '../Components/CustomNavbar';
//import Salsa from '../Components/Salsa';
import PeanutButter from '../Components/PeanutButter';
import Footer from '../Components/Footer';



const Salsaa = () => {
  return (
    <>
    <CustomNavbar/>
    <PeanutButter/>
    <Footer/>  
    
    </>
  );
};

export default PeanutButter; */

import React from 'react';
import CustomNavbar from '../Components/CustomNavbar';
import Peanutbutter from '../Components/PeanutButter';
import Footer from '../Components/Footer';


const PeanutButter = () => {
  return (
    <>
    <CustomNavbar/>
    <Peanutbutter/>
    <Footer/>  
    
    </>
  );
};

export default PeanutButter;