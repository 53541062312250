// src/components/VisionMissionCoreValues.js

import React from 'react';
import { Box, Step, StepLabel, Stepper, Typography, Paper } from '@mui/material';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Zoom } from 'react-awesome-reveal';
import { CheckCircle, Search, Stars } from '@mui/icons-material'; // Import icons
/* import visionImage from '../images/vision.png'; // Import vision graphic
import missionImage from '../images/mission.png'; // Import mission graphic
import valuesImage from '../images/core-values.png'; // Import core values graphic */
import homeSide from '../images/home-side-2.jpg'; // Import the image

/* const steps = [
  { label: 'Our Vision', icon: <Search />, image: visionImage },
  { label: 'Our Mission', icon: <CheckCircle />, image: missionImage },
  { label: 'Our Core Values', icon: <Stars />, image: valuesImage },
]; */
const steps = [
    { label: 'Our Vision', icon: <Search />  },
    { label: 'Our Mission', icon: <CheckCircle />  },
    { label: 'Our Core Values', icon: <Stars />  },
  ];
const VisionMission = () => {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleStepClick = (index) => {
    setActiveStep(index); // Update the active step when clicked
  };

  return (
    <Container fluid className="VisionMission" style={{ padding: '40px 5%',fontFamily: 'Cambria', fontWeight: 'bold' }}>
 
      <h2 className="text-center mb-4">Our Vision & Mission</h2>
      <Row>
     {/*  <Col md={5} className="text-center">
          <Zoom>
            <Card className="mb-3 border-0">
              <Card.Body>
                <img  src={homeSide} alt="Why Us" style={{ align:'right', width: '70%', borderRadius: '10px' }} />
              </Card.Body>
            </Card>
          </Zoom>
        </Col> */}
        <Col md={12} className="text-right">
    <Box sx={{ padding: '40px 10%', textAlign: 'center', backgroundColor: '#f9f9f9' }}>
      {/* <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold' }}>
        Our Vision, Mission & Core Values
      </Typography> */}
     
      <Stepper activeStep={activeStep} alternativeLabel sx={{ marginBottom: '30px' }}>
        {steps.map((step, index) => (
          <Step key={step.label} onClick={() => handleStepClick(index)} sx={{ cursor: 'pointer' }}>
            <StepLabel
              StepIconComponent={() => (
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  {step.icon}
                  <Typography variant="caption" sx={{ marginTop: 0.5,fontSize: '18px', fontFamily: 'Cambria' }}>
                    {step.label}
                  </Typography>
                   
                </Box>
              )}
            />
          </Step>
        ))}
      </Stepper>
      

      <Paper elevation={3} sx={{ padding: '20px', borderRadius: '8px' }}>
        <Box sx={{ marginTop: '20px', textAlign: 'center', fontFamily: 'Cambria'  }}>
          {activeStep === 0 && (
            <>
              <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '10px', fontFamily: 'Cambria'  }}>Our Vision</Typography>
            {/*   <img src={steps[0].image} alt="Vision Graphic" style={{ width: '100%', maxWidth: '400px', marginBottom: '20px' }} /> */}
              <Typography variant="body2" style={{ fontSize: '18px', fontFamily: 'Cambria'  }} >
              To emerge as a global powerhouse in the food industry by consistently delivering innovative, high-quality, and sustainable products that cater to the evolving needs of our customers. We envision becoming a trusted name synonymous with excellence, enriching lives through superior culinary experiences, and contributing meaningfully to a healthier, more sustainable world.             </Typography>
            </>
          )}
          {activeStep === 1 && (
            <>
              <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '10px', fontFamily: 'Cambria'  }}>Our Mission</Typography>
             {/*  <img src={steps[1].image} alt="Mission Graphic" style={{ width: '100%', maxWidth: '400px', marginBottom: '20px' }} />
           */}    <Typography variant="body2" style={{ fontSize: '18px' , fontFamily: 'Cambria'  }} >
               Our mission is to redefine standards in the food industry by producing premium, internationally acclaimed products that inspire trust and satisfaction. Through ethical practices, innovation, and a relentless focus on quality, we aim to foster long-term partnerships with customers and stakeholders. We are committed to meeting diverse market demands, enhancing customer experiences, and making a positive impact on global food sustainability.  
               </Typography>
            </>
          )}
          {activeStep === 2 && (
            <>
              <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '10px', fontFamily: 'Cambria'  }}>Our Core Values</Typography>
           {/*    <img src={steps[2].image} alt="Core Values Graphic" style={{ width: '100%', maxWidth: '400px', marginBottom: '20px' }} />
           */}    <Typography variant="body2" style={{ fontSize: '18px', fontFamily: 'Cambria'  }} >
               1. <b>Quality Excellence</b>: Upholding the highest standards in every product we create<br></br>
               2. <b>Customer Focus</b>: Delivering unparalleled satisfaction through tailored solutions and exceptional service<br></br>
               3. <b>Integrity</b>: Conducting business with honesty, transparency, and respect<br></br>
               4. <b>Innovation</b>: Continuously evolving through creative ideas and advanced technologies<br></br>
               5. <b>Sustainability</b>: Advocating eco-friendly practices to ensure a better tomorrow<br></br>
               6. <b>Collaboration</b>: Empowering teamwork to achieve collective success<br></br>

              </Typography>
            </>
          )}
        </Box>
      </Paper>
    </Box>
    </Col>
       
        </Row>
        </Container>
  );
};

export default VisionMission;
