import React from 'react';
import CustomNavbar from '../Components/CustomNavbar';
import ChineseSauce from '../Components/ChineseSauces';
import Footer from '../Components/Footer';


const ChineseSauces = () => {
  return (
    <>
    <CustomNavbar/>
    <ChineseSauce/>
    <Footer/>  
    
    </>
  );
};

export default ChineseSauces;