import React from 'react';
import CustomNavbar from '../Components/CustomNavbar';
import Slider from '../Components/SliderTop';
import CategoryCarousel from '../Components/CategoryCarousel';
import CountryCarousel from '../Components/CountryCarousel'
/* import Overview from '../Components/Overview'; */

import WhyChooseUs  from '../Components/WhyChooseUs';
import ParallaxSection from '../Components/ParallaxSection';
// import YummyliciousRecipes from '../Components/YummyliciousRecipes';
import ClientNetworkSection from '../Components/ClientNetworkSection';
import VisionMission from '../Components/VisionMission';
import Footer from '../Components/Footer';
const HomePage = () => {
  return (
    <>
      <CustomNavbar />
      <Slider />
    {/*   <Overview/>  */}
      <CategoryCarousel />
      <VisionMission/>
      <WhyChooseUs />
      <CountryCarousel />
      <ParallaxSection />
     {/* <YummyliciousRecipes/> */}
      <ClientNetworkSection/>
      <Footer/>
    </>
  );
};
/* <section className="section swiper-container swiper-slider swiper-slider-3" data-loop="true">
            <div className="swiper-wrapper context-dark text-md-start">
                <video width="100%" type="video/mp4"
                src="images/slide-vdo.mp4" loop  muted
                playsinline autoplay="true" controlslist="nodownload" disablepictureinpicture="yes"></video>
                </div>
                </section> */
export default HomePage;


