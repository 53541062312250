import React from 'react';
import Slider from 'react-slick';
import { Container, Image } from 'react-bootstrap';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Certifications.css';

const images = [
    { src: 'images/certifications/nabcb.png', title: 'NABCB'  },
    { src: 'images/certifications/fssc-22000.png', title: 'FSSC 22000' },
    { src: 'images/certifications/halal-food.png', title: 'Halal Food' },
    { src: 'images/certifications/brc-food.png', title: 'British Retail Constorium' },
    { src: 'images/certifications/intertek_ukas.jpg', title: 'Intertek UKAS'},
    { src: 'images/certifications/jblogo.png', title: 'Jaivik Bharat'},
    { src: 'images/certifications/sedex.jpg', title: 'Sedex' }
];

const Certifications = () => {
  const settings = {
    dots: false, // Remove pagination dots
    infinite: true,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (

    <Container fluid className="my-4">  <h2 className="wow fadeScale">
   Certifications
  </h2>
      <Slider {...settings}>
        {images.map((image, idx) => (
          <div key={idx} className="carousel-cr-image-col">
            <Image src={image.src} alt={image.title} className="d-block w-100 category-cr-image" />
            <div className="overlay-cr-text">HELLO</div> {/* Overlay text */}
          </div>
        ))}
      </Slider>
    </Container>
  );
};

export default Certifications;
