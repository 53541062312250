import React, { useState } from 'react';
import './Capability.css'; // Custom styles
import { Container, Row, Col } from 'react-bootstrap';

import Certifications from './Certifications';

const Capability = () => {
  const [zoomedImageIndex, setZoomedImageIndex] = useState(null); // State to hold the index of the zoomed image

  const images = [
    { src: "../images/FCI/204.jpeg", alt: "Image 1", title: "" },
   /*  { src: "../images/FCI/1346.jpg", alt: "Image 2", title: "" },
    { src: "../images/FCI/1356.jpg", alt: "Image 3", title: "" }, */
    { src: "../images/FCI/1364.jpg", alt: "Image 4", title: "" },
    { src: "../images/FCI/1426.jpg", alt: "Image 5", title: "" },
    // { src: "../images/FCI/1435.jpg", alt: "Image 6", title: "" },
    { src: "../images/FCI/1438.jpg", alt: "Image 7", title: "" },
    { src: "../images/FCI/1447.jpg", alt: "Image 8", title: "" },
    { src: "../images/FCI/1455.jpg", alt: "Image 9", title: "" },
    { src: "../images/FCI/1473.jpg", alt: "Image 10", title: "" }, 
    { src: "../images/FCI/4.jpeg", alt: "Image 10", title: "" }, 
   // { src: "../images/FCI/1.jpeg", alt: "Image 10", title: "" }, 
   // { src: "../images/FCI/3.jpeg", alt: "Image 10", title: "" },
    { src: "../images/FCI/1.jpg", alt: "Image 9", title: "" },
    { src: "../images/FCI/2.jpg", alt: "Image 10", title: "" }, 
    { src: "../images/FCI/3.jpg", alt: "Image 10", title: "" }, 
    { src: "../images/FCI/4.jpg", alt: "Image 10", title: "" }, 
    { src: "../images/FCI/5.jpg", alt: "Image 10", title: "" },
  ];

  const handleImageClick = (index) => setZoomedImageIndex(index); // Open zoom modal
  const handleCloseZoom = () => setZoomedImageIndex(null); // Close zoom modal
  const handlePrevious = () => setZoomedImageIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : images.length - 1)); // Go to previous image
  const handleNext = () => setZoomedImageIndex((prevIndex) => (prevIndex < images.length - 1 ? prevIndex + 1 : 0)); // Go to next image

  return (
    <>
      {/* Breadcrumb Section */}
      {/* <section className="breadcrumbs-custom" style={{ marginBottom: '5em' }}>
        <div
          className="parallax-container"
          style={{ backgroundImage: `url(images/banner/capability.png)` }}
        >
          <div className="breadcrumbs-custom-body parallax-content context-dark right-banner-text">
            <div className="container" style={{ color: '#333' }}>
              <h2 className="breadcrumbs-custom-title">Gallery</h2>
              <h5 className="breadcrumbs-custom-text">
                Foodcoast International - Authentic Freshness & Flavour
              </h5>
            </div>
          </div>
        </div>
        <div className="breadcrumbs-custom-footer">
          <div className="container">
            <ul className="breadcrumbs-custom-path">
              <li>
                <a href="../">Home</a>
              </li>
              <li className="active">Gallery</li>
            </ul>
          </div>
        </div>
      </section> */}

      {/* Image Gallery Section */}
      <section className="section bg-image-1 text-md-start" style={{ padding: "50px 0" }}>
        <Container>
        
     
          <h2 className="text-center mb-4">Life Beyond Taste</h2>
          <div className="row">
            {images.map((image, index) => (
              <div key={index} className="col-md-4 col-sm-6 col-6 text-center mb-4">
                <img
                  src={image.src}
                  alt={image.alt}
                  className="zoomImg"
                  style={{
                    width: '100%',
                    height: 'auto',
                    borderRadius: '8px',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleImageClick(index)} // Open zoom on image click
                />
                <p style={{ marginTop: '5px', fontSize: '18px', color: '#555' }}>
                  {image.title}
                </p>
              </div>
            ))}
          </div>
        </Container>
      </section>
      <Certifications />

      {/* Zoom Modal Section */}
      {zoomedImageIndex !== null && (
        <div className="zoom-modal" onClick={handleCloseZoom}>
          <button
            className="nav-button prev"
            onClick={(e) => {
              e.stopPropagation();
              handlePrevious();
            }}
          >
            <i className="fa fa-chevron-left"></i> {/* Previous Icon */}
          </button>
          <img
            src={images[zoomedImageIndex].src}
            alt={images[zoomedImageIndex].alt}
            className="zoomed-image"
          />
          <button
            className="nav-button next"
            onClick={(e) => {
              e.stopPropagation();
              handleNext();
            }}
          >
            <i className="fa fa-chevron-right"></i> {/* Next Icon */}
          </button>
        </div>
      )}
    </>
  );
};

export default Capability;
