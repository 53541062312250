import React, { useState } from 'react';
import './Foodcoast.css'; // Custom styles
import { Container, Row, Col } from 'react-bootstrap';
import { FaDownload } from 'react-icons/fa'; // Import the download icon

const Foodcoast = () => {
  const [zoomedImage, setZoomedImage] = useState(null); // State to hold the zoomed image

  const images = [
    { src: "../images/categories/c1.png", alt: "Mayonnaise", title: "Mayonnaise", link: "/mayonnaise" },
    { src: "../images/categories/c2.png", alt: "Tomato Ketchup", title: "Tomato Ketchup", link: "/tomato-ketchup" },
    { src: "../images/categories/c3.png", alt: "Sandwich Spreads", title: "Sandwich Spreads", link: "/sandwich-spreads" },
    { src: "../images/categories/c4.png", alt: "Salad Dressings", title: "Salad Dressings", link: "/salad-dressings" },
    { src: "../images/categories/c5.png", alt: "Chinese Sauces", title: "Chinese Sauces", link: "/chinese-sauces" },
    { src: "../images/categories/c6.png", alt: "Italian Sauces", title: "Italian Sauces", link: "/italian-sauces" },
    { src: "../images/categories/c7.png", alt: "Dips", title: "Dips", link: "/dips" },
    { src: "../images/categories/c8.png", alt: "Salsa", title: "Salsa", link: "/salsa" },
    { src: '../images/categories/c10.jpg',  alt: "Peanut Butter", title: 'Peanut Butter', link: '/peanut-butter' },
    { src: '../images/categories/c11.jpg',  alt: "Sweet Topping", title: 'Sweet Toppings', link: '/sweet-toppings' },
    { src: '../images/categories/c9.jpg',  alt: "Chutneys", title: 'Chutneys', link: '/chutneys' }
  ];

  return (
    <>
      {/* Breadcrumb Section */}
      {/* <section className="breadcrumbs-custom" style={{ marginBottom: '5em' }}>
        <div
          className="parallax-container"
          style={{ backgroundImage: `url(images/banner/foodcoast.png)` }}
        >
          <div className="breadcrumbs-custom-body parallax-content context-dark right-banner-text">
            <div className="container"  style={{ color: '#333' }}>
              <h2 className="breadcrumbs-custom-title">Foodcoast</h2>
              <h5 className="breadcrumbs-custom-text">
                Foodcoast International - Authentic Freshness & Flavour
              </h5>
            </div>
          </div>
        </div>
        <div className="breadcrumbs-custom-footer">
          <div className="container"  style={{ color: '#333' }}>
            <ul className="breadcrumbs-custom-path">
              <li><a href="../">Home</a></li>
              <li className="active">Foodcoast</li>
            </ul>
          </div>
        </div>
      </section> */}

      {/* Image Gallery Section */}
      <section className="section bg-image-1 text-md-start" style={{ padding: "50px 0" }}>
        <Container> 
          <div className="d-flex justify-content-between align-items-center mb-4">
            <h2 className="text-center mb-4"> </h2>
            <a
              href="../images/FCI-brochure.pdf"
              download
              className="btn btn-danger d-flex align-items-center"
              style={{ textDecoration: 'none', color: 'white' }}
            >
              <FaDownload style={{ marginRight: '8px' }} /> Download Brochure
            </a>
          </div><h2 className="text-center mb-4">Product Categories</h2>
          <div className="row justify-content-center">
            {images.map((image, index) => (
              <a
                key={index}
                href={image.link} // Added clickable links
                className="col-md-4 col-sm-6 col-6 image-container mb-4"
                style={{
                 // background: '#f5f5f5',
               //   border: '1px solid #ddd',
               //   borderRadius: '8px',
                  width: '350px', // Kept width
                  margin: '10px', // Increased margin
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textDecoration: 'none', // Remove underline for links
                  transition: 'transform 0.3s ease',
                }}
                onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.05)')}
                onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
              >
                <img
                  src={image.src}
                  alt={image.alt}
                  style={{
                    maxWidth: '90%',
                    maxHeight: '70%',
                    objectFit: 'contain',
                  }}
                />
                <p style={{ marginTop: '2px', fontSize: '18px', color: '#555' }}>
                  {image.title}
                </p>
              </a>
            ))}
          </div>
        </Container>
      </section>
    </>
  );
};

export default Foodcoast;
